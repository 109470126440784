import React from "react";
import { Waypoint } from "react-waypoint";
import imagesLoaded from "imagesloaded";

// Partials Template
import { Marquee } from "./partials/Marquee";
import { About } from "./partials/About";
import { Screen } from "./partials/Screen";
import { Video } from "./partials/Video";
import { Download } from "./partials/Download";
import { Community } from "./partials/Community";
import { Footer } from "../components/Footer";

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: "",
      about: "",
      screens: "",
      videos: "",
      download: "",
      community: ""
    };
  }
  componentDidMount() {
    this.imagesloaded = imagesLoaded(this.imagesloaded, { background: true });
  }
  render() {
    return (
      <main id="home" ref={el => (this.imagesloaded = el)}>
        <Waypoint onEnter={() => this.setState({ hero: "fadeInUp" })}>
          <section id="hero" className="section">
            <Marquee viewable={this.state.hero} />
          </section>
        </Waypoint>
        <Waypoint
          onEnter={() => this.setState({ about: "fadeInUp" })}
          bottomOffset="10%"
        >
          <section id="about" className="section">
            <About viewable={this.state.about} />
          </section>
        </Waypoint>
        <div id="media">
          <Waypoint
            onEnter={() => this.setState({ screens: "fadeInUp" })}
            bottomOffset="10%"
          >
            <section id="gallery" className="section">
              <Screen viewable={this.state.screens} />
            </section>
          </Waypoint>
          <Waypoint
            onEnter={() => this.setState({ videos: "fadeInUp" })}
            bottomOffset="10%"
          >
            <section id="videos" className="section d-none d-md-block">
              <Video viewable={this.state.videos} />
            </section>
          </Waypoint>
        </div>
        <Waypoint
          onEnter={() => this.setState({ download: "fadeInUp" })}
          bottomOffset="10%"
        >
          <section id="download" className="section">
            <Download viewable={this.state.download} />
          </section>
        </Waypoint>
        <Waypoint
          onEnter={() => this.setState({ community: "fadeInUp" })}
          bottomOffset="10%"
        >
          <section id="community" className="section">
            <Community viewable={this.state.community} />
            <Footer />
          </section>
        </Waypoint>
      </main>
    );
  }
}
