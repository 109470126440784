import React from "react";
import i18next from "i18next";
import { Container, Image } from "react-bootstrap";
import { Heading } from "../../components/Heading";
import { Content } from "../../components/Content";
import { Link } from "../../components/Link";

// Assets
import DownloadSoliderImage from "../../assets/download_soldiers.png";

export class Download extends React.Component {
  render() {
    const storeBtns = i18next.t("store.buttons", {
      returnObjects: true
    });
    return (
      <Container fluid>
        <div className="section-wrapper">
          <Heading
            className="display-1 text-center"
            delay="delay-3"
            title={i18next.t("download.title")}
            options={{
              animation: this.props.viewable
            }}
          />
          <div
            className={`overlay center inview animated ${this.props.viewable}`}
          >
            <Image
              src={DownloadSoliderImage}
              alt={i18next.t("download.title")}
              fluid
            />
          </div>
          <Content
            title={i18next.t("download.title")}
            content={""}
            options={{
              animation: this.props.viewable
            }}
          >
            <div
              className={`link-wrapper inview animated delay-4 ${this.props.viewable}`}
            >
              {storeBtns.map(function(button) {
                return (
                  <Link
                    key={button.title}
                    link={button.link}
                    title={button.title}
                    options={{
                      external: true,
                      rel: true
                    }}
                  >
                    <Image
                      src={require(`../../assets/${button.image}.png`)}
                      alt={button.title}
                      fluid
                    />
                  </Link>
                );
              })}
            </div>
          </Content>
        </div>
      </Container>
    );
  }
}
