import React from "react";
import { Header } from "./components/Header";
import { Home } from "./pages/Home";
import "./styles/App.scss";
import ReactGA from "react-ga";
ReactGA.initialize("UA-175692600-2");

const page = window.location.pathname;
ReactGA.set({ page });
ReactGA.pageview(page);

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
    </div>
  );
}
export default App;
