import React from "react";
import i18next from "i18next";
import { Container, Image, ResponsiveEmbed } from "react-bootstrap";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Assets
import Shell from "../../assets/shell.png";
import ArrowRight from "../../assets/down_arrows_right.png";
import ArrowLeft from "../../assets/down_arrows_left.png";
import PlayBtn from "../../assets/play.png";

const isiPad =
  (/iPad/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
  !window.MSStream;

export class Video extends React.Component {
  constructor(props) {
    super(props);
    this.player = "";
    this.state = { active: "", player: "" };
    this.lgMedia = window.matchMedia(`(min-width: 768px)`);
    this.video = i18next.t("videos.url") + "&enablejsapi=1";
    this.videoCode = i18next.t("videos.code");
    this.videoReady = false;
  }
  toggleClass() {
    this.setState({
      active: "active"
    });
    this.player.playVideo();
  }
  componentDidMount() {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = this.loadVideo;
    }
  }
  componentWillUnmount() {
    window.onscroll = null;
  }
  loadVideo = () => {
    this.player = new window.YT.Player(`youtube-player-${this.videoCode}`, {
      events: {
        onReady: this.onPlayerReady
      }
    });
  };
  onPlayerReady = () => {
    this.setState({
      player: "on"
    });
    window.onscroll = () => {
      if (this.player) {
        let elementTarget = document.getElementById("videos");
        let elemHeightBottom = elementTarget.offsetTop + 400;
        let elemHeightTop = elementTarget.offsetTop - 800;
        if (
          window.scrollY > elemHeightBottom ||
          window.scrollY < elemHeightTop
        ) {
          this.player.pauseVideo();
        }
      }
    };
    window.onresize = () => {
      if (this.player) {
        this.player.pauseVideo();
      }
    };
  };
  onPlayerStateChange = () => {};
  render() {
    return (
      <Container>
        <div className="shells">
          <Image src={Shell} alt={i18next.t("media.title")} />
          <Image src={Shell} alt={i18next.t("media.title")} />
          <Image src={Shell} alt={i18next.t("media.title")} />
        </div>
        <div className="overlay right">
          <Image src={ArrowRight} alt={i18next.t("media.title")} fluid />
        </div>
        <div className="overlay left">
          <Image src={ArrowLeft} alt={i18next.t("media.title")} fluid />
        </div>
        <div className="section-wrapper">
          <div
            className={`video-container ${this.lgMedia.match ? "d-none" : ""}`}
          >
            <ResponsiveEmbed aspectRatio="16by9">
              <div
                className={`iframe-wrapper ${this.state.player} ${
                  this.state.active
                } ${isiPad ? "isiPad" : ""}`}
              >
                <div className="spinner">
                  <FontAwesomeIcon icon={faCircleNotch} spin size="5x" />
                </div>
                <div className="playbtn" onClick={this.toggleClass.bind(this)}>
                  <Image src={PlayBtn} fluid />
                </div>
                <iframe
                  id={`youtube-player-${this.videoCode}`}
                  src={this.video}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title={i18next.t("media.title")}
                />
              </div>
            </ResponsiveEmbed>
          </div>
        </div>
      </Container>
    );
  }
}
