import React from "react";
import { Container } from "react-bootstrap";

export class Hero extends React.Component {
  render() {
    const lgMedia = window.matchMedia(`(min-width: 768px)`);
    return (
      <div>
        {this.props.video && lgMedia.matches ? (
          <div className="video-bg">
            <div className="video-bg-inner">
              <video
                id="video"
                loop
                autoPlay
                muted
                src={require(`../assets/${this.props.video}`)}
              />
            </div>
          </div>
        ) : null}
        <div className="hero-wrapper">
          <Container className="h-100" fluid>
            <h1
              className={`display-2 inview animated delay-1 ${this.props.options.animation}`}
            >
              {this.props.title}
            </h1>
            <div
              className={`inview animated delay-3 ${this.props.options.animation}`}
            >
              {this.props.children}
            </div>
          </Container>
        </div>
        {this.props.options.scroll === true ? (
          <div className="scroll-down-arrow">
            <div className="chevron"></div>
            <div className="chevron"></div>
            <div className="chevron"></div>
          </div>
        ) : null}
      </div>
    );
  }
}
