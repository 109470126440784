import React from "react";
import i18next from "i18next";
import { Link } from "./Link";
import {
  faTwitter,
  faFacebookF,
  faYoutube,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class Social extends React.Component {
  render() {
    return (
      <div className="social-icons">
        {this.props.title ? (
          <h3 className={this.props.className ? this.props.className : ""}>
            {this.props.title}
          </h3>
        ) : (
          ""
        )}
        <Link
          link={i18next.t("social.facebook")}
          title="Facebook"
          options={{
            external: true,
            rel: true
          }}
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </Link>
        <Link
          link={i18next.t("social.twitter")}
          title="Twitter"
          options={{
            external: true,
            rel: true
          }}
        >
          <FontAwesomeIcon icon={faTwitter} />
        </Link>
        <Link
          link={i18next.t("social.youtube")}
          title="Youtube"
          options={{
            external: true,
            rel: true
          }}
        >
          <FontAwesomeIcon icon={faYoutube} />
        </Link>
        <Link
          link={i18next.t("social.instagram")}
          title="Instagram"
          options={{
            external: true,
            rel: true
          }}
        >
          <FontAwesomeIcon icon={faInstagram} />
        </Link>
      </div>
    );
  }
}
