import React from "react";
import i18next from "i18next";
import { Link } from "./Link";

export class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="mx-auto w-70 footer-wrapper">
          <div className="footer-brand">
            <h2>WARPATH</h2>
          </div>
          <span>
            &copy; {new Date().getFullYear()}, {i18next.t("footer.copyright")}
            <span className="links d-block d-md-inline-block">
              <Link
                link={i18next.t("footer.termslink")}
                title={i18next.t("footer.terms")}
                options={{
                  external: true,
                  rel: true
                }}
              >
                {i18next.t("footer.terms")}
              </Link>
              <span>and</span>
              <Link
                link={i18next.t("footer.privacylink")}
                title={i18next.t("footer.privacy")}
                options={{
                  external: true,
                  rel: true
                }}
              >
                {i18next.t("footer.privacy")}
              </Link>
            </span>
          </span>
        </div>
      </footer>
    );
  }
}
