import React from "react";

export class Link extends React.Component {
  render() {
    return (
      <a
        href={this.props.link}
        className={this.props.className ? this.props.className : ""}
        target={
          this.props.options && this.props.options.external ? "_blank" : ""
        }
        rel={
          this.props.options && this.props.options.rel
            ? "noopener noreferrer"
            : ""
        }
        alt={this.props.title}
      >
        {this.props.children}
      </a>
    );
  }
}
