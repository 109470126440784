import React from "react";
import i18next from "i18next";
import { ResponsiveEmbed, Modal, Button } from "react-bootstrap";
import { Link } from "../../components/Link";
import { Hero } from "../../components/Hero";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class Marquee extends React.Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false
    };
  }

  handleClose() {
    this.setState({ show: false, active: "" });
  }

  handleShow() {
    this.setState({ show: true, active: "active" });
  }

  render() {
    return (
      <div>
        <Hero
          title={i18next.t("hero.title")}
          video={i18next.t("hero.video")}
          options={{
            animation: this.props.viewable,
            scroll: true
          }}
        ></Hero>
        <div className="inview animated delay-3 fadeInUp">
          <div className="play-wrapper d-md-none">
            <div
              className={`playtbn ${this.state.active}`}
              onClick={this.handleShow}
            ></div>
          </div>
        </div>
        <div className="app-links inview animated delay-2 fadeInUp">
          <Link
            className="btn btn-theme"
            link={i18next.t("playnow.link")}
            title={i18next.t("playnow.title")}
            options={{
              external: true,
              rel: true
            }}
          >
            {i18next.t("playnow.title")}
          </Link>
        </div>
        {this.state.show ? (
          <Modal
            id="mobile-video-modal"
            animation={false}
            show={this.state.show}
            onHide={this.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Body>
              <Button
                className="modal-video-close-btn"
                onClick={this.handleClose}
              ></Button>
              <div className="spinner">
                <FontAwesomeIcon icon={faCircleNotch} spin size="3x" />
              </div>
              <ResponsiveEmbed aspectRatio="16by9">
                <iframe
                  src={i18next.t("videos.url") + "&autoplay=1"}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title={i18next.t("playnow.title")}
                />
              </ResponsiveEmbed>
            </Modal.Body>
          </Modal>
        ) : null}
      </div>
    );
  }
}
