import React from "react";
import i18next from "i18next";
import { Container } from "react-bootstrap";
import { Social } from "../../components/Social";
import { Heading } from "../../components/Heading";
import { Content } from "../../components/Content";
import { Link } from "../../components/Link";

export class Community extends React.Component {
  render() {
    const communityBtns = i18next.t("community.buttons", {
      returnObjects: true
    });
    return (
      <Container fluid>
        <div className="section-wrapper">
          <Heading
            className="display-3"
            delay="delay-3"
            title={i18next.t("community.title")}
            options={{
              animation: this.props.viewable
            }}
          />
          <Content
            className="section mx-auto"
            delay="delay-4"
            title={i18next.t("community.title")}
            options={{
              animation: this.props.viewable
            }}
          >
            <div className="d-md-flex pb-3 justify-content-around">
              {communityBtns.map(function(button) {
                return (
                  <Link
                    key={button.title}
                    className="linkout mx-1"
                    link={button.link}
                    title={button.title}
                    options={{
                      external: true,
                      rel: true
                    }}
                  >
                    <h3>{button.title}</h3>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#ffffff"
                          d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"
                        />
                      </svg>
                    </span>
                  </Link>
                );
              })}
            </div>
            <Social title={i18next.t("social.title")} className="display-4" />
          </Content>
        </div>
      </Container>
    );
  }
}
