import React from "react";
import i18next from "i18next";
import { Container } from "react-bootstrap";
import { Heading } from "../../components/Heading";
import { Content } from "../../components/Content";

export class About extends React.Component {
  render() {
    return (
      <Container fluid>
        <div className="section-wrapper">
          <Heading
            className="display-2 text-center"
            delay="delay-3"
            title={i18next.t("about.title")}
            options={{
              animation: this.props.viewable
            }}
          />
          <Content
            className="section d-flex align-items-center container-sl"
            delay="delay-4"
            title={i18next.t("about.title")}
            content={i18next.t("about.content")}
            options={{
              animation: this.props.viewable
            }}
          />
        </div>
      </Container>
    );
  }
}
